<template>
    <div class="tools">
        <el-button @click="openFilter" :disabled="loading">{{lang['html.el-button.openFilter']}}</el-button>
        <el-button @click="this.$router.go(0);" :disabled="loading">{{lang['html.el-button.refresh']}}</el-button>
        <el-button @click="viewTableData(1)" :disabled="loading">{{lang['html.el-button.viewTableData.1']}}</el-button>
        <el-button @click="viewTableData(2)" :disabled="loading">{{lang['html.el-button.viewTableData.2']}}</el-button>
        <el-button @click='outputData' :disabled="loading">{{lang['html.el-button.outputData']}}</el-button>
        <!--        [跨级]下级代理数据类型-->
        <el-checkbox
            style="margin-left: 10px"
            v-model="agentDataType"
            :label="lang['html.el-checkbox.AgentDataType']"
            size="small"
            @change="changeAgentDataType"
            v-show="agentDataTypeVisible"
            :disabled="loading"
        />
    </div>
    <el-table :data="tableData"
              style="width: 300%"
              stripe
              border

              v-loading=loading
              element-loading-text="Loading..."
              element-loading-background="rgba(0, 0, 0, 0.8)"

              @selection-change="handleSelectionChange"
    >
        <el-table-column type="selection" width="50"/>

        <el-table-column label="id" width="90" highlight-current-row>
            <template #default="scope">
                {{ scope.row.id }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.username']" width="105" highlight-current-row>
            <template #default="scope">
                {{ scope.row.username }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.name']" width="100" highlight-current-row>
            <template #default="scope">
                {{ scope.row.name }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.contact']" width="150" highlight-current-row>
            <template #default="scope">
                {{ scope.row.contact }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.nickname']" width="100" highlight-current-row>
            <template #default="scope">
                {{ scope.row.nickname }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.money_reg']" width="100" highlight-current-row>
            <template #default="scope">
                {{ scope.row.money_reg }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.money_rec']" width="100" highlight-current-row>
            <template #default="scope">
                {{ scope.row.money_rec }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.leader']" width="100" highlight-current-row>
            <template #default="scope">
                {{ scope.row.leader }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.reg_time']" width="160" highlight-current-row>
            <template #default="scope">
                {{ scope.row.reg_time }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.login_time']" width="160" highlight-current-row>
            <template #default="scope">
                {{ scope.row.login_time }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.status']" width="60" highlight-current-row>
            <template #default="scope">
                {{ scope.row.status === 1 ? lang['html.el-table.template.status.1'] : lang['html.el-table.template.status.0'] }}
            </template>
        </el-table-column>


        <el-table-column label="Tools">
            <template #default="scope">
                <el-dropdown>
                    <span class="el-dropdown-link">
                        {{lang['html.el-table.tools']}}
                        <el-icon class="el-icon--right">
                            <arrow-down/>
                        </el-icon>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="tableMenu(scope.row,1)">{{lang['html.el-dropdown-item.tableMenu.1']}}</el-dropdown-item>
                            <el-dropdown-item @click="tableMenu(scope.row,2)">{{lang['html.el-dropdown-item.tableMenu.2']}}</el-dropdown-item>
<!--                            <el-dropdown-item @click="tableMenu(scope.row,3)">{{lang['html.el-dropdown-item.tableMenu.3']}}</el-dropdown-item>-->
                            <el-dropdown-item @click="tableMenu(scope.row,4)">{{lang['html.el-dropdown-item.tableMenu.4']}}</el-dropdown-item>
                            <el-dropdown-item @click="tableMenu(scope.row,5)">{{lang['html.el-dropdown-item.tableMenu.5']}}</el-dropdown-item>
                            <el-dropdown-item @click="tableMenu(scope.row,6)">{{lang['html.el-dropdown-item.tableMenu.6']}}</el-dropdown-item>
                            <el-dropdown-item @click="tableMenu(scope.row,7)">{{lang['html.el-dropdown-item.tableMenu.7']}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: left;display: inline">
        <el-pagination background
                       layout="total,prev, pager, next,sizes,"
                       :total=total
                       style="margin-top: 10px"
                       v-show="pageShow"
                       :disabled="loading"


                       v-model:currentPage="nowPage"
                       @current-change="changePage"

                       v-model:page-size="pageSize"
                       :page-sizes="[20,50,100,500,1000]"
                       @size-change="handleSizeChange"
        />
    </div>

<!--    自定义组件-->
<!--    个人信息-->
    <agentListInfo
        ref=agentListInfoRef
        @updateTable="updateTable"
    />
<!--    代理商筛选器-->
    <agentListFilter ref="agentListFilterRef"
                    :tableData=this.tableDataAll
                    @watch-filter=watchFilterData
    />
<!--    [充值/回收]下级额度-->
    <agentListChangeMoney
        ref="agentLIstChangeMoneyRef"
        @updateTable="updateTable"
    />
</template>

<script>
import paging from "../../utils/paging";
import agentListInfo from './agentList/agentList-info';
import agentListFilter from './agentList/agentList-filter';
import agentListChangeMoney from  './agentList/agentList-changeMoney';
import User from "../../utils/User";
export default {
    name: "agentList",
    components:{
        agentListInfo,
        agentListFilter,
        agentListChangeMoney
    },
    data() {
        return {
            loading: true,//正在加载数据遮蔽窗
            tableData: [],//当前表格显示数据
            total: 0,//表格总数
            nowPage: 1,//当前页数
            pageSize:20,
            selectTableData: [],//当前被多选的用户
            tableDataAll: [],//表格全部数据(未分页)
            pageShow: false,//分页组件是否显示
            filterData: [],//筛选后的数据
            lang:this.$lang.getLang('agent','agentList'),
            agentDataType:false,//[下级代理数据类型]false=只显示自己旗下的用户 true=显示包含下级代理商的用户
            agentDataTypeVisible:false,//[下级代理数据类型]是否显示功能
            agentUserName:User.getUserName(),//当前代理商账号
            nowEditAgentId:0,//当前正在编辑用户数据的用户id
        }
    },
    methods: {
        getAgentList() {
            this.loading = true;
            this.$notify({message: this.lang['getAgentList.wait'], type: 'info',});
            this.$api.post('Agent.AgentData/getAgentList', {
                agentDataType: this.agentDataType ? 1 : 2,
            }).then((res) => {
                let data = this.$helper.checkRes(res);
                if (!data) {
                    this.$alert(this.lang['getAgentList.error']);
                    return;
                }
                paging.init(data.data, 20)
                this.tableDataAll = paging.getAllData();
                this.getTableData()
                this.loading = false;
                this.$notify({message: this.lang['getAgentList.tips.num'] + this.total, type: 'success',});
            }).catch(err=>{
                this.$helper.axiosCatch(err);
                this.loading = false;
            });
        },
        //更新表格数据
        getTableData() {
            this.tableData = paging.getPageData();
            this.total = paging.getLength();
            this.pageShow = true;
        },
        /**
         * 被多选
         */
        handleSelectionChange(selectArr) {
            this.selectTableData = selectArr;
        },
        //本函数被调用则页数被改动,并实时刷新表格数据
        changePage(newPage) {
            paging.setPage(newPage);
            this.getTableData();
        },
        //每页显示数量变动
        handleSizeChange(size){
            this.loading = true;
            this.$notify({message: this.lang['getAgentList.wait'], type: 'info',});
            this.pageSize = size;
            paging.setSize(size);
            setTimeout(()=>{
                this.changePage(1);
                this.loading = false;
            },800);
        },
        /**
         * [表格]行内菜单
         * @param row 行内数据
         * @param type 操作类型,1.编辑信息 2.充值余额  4.回收余额 5.查询卡密 6.下级用户管理 7.日志查询
         */
        tableMenu(row,type){
            let agentId = this.nowEditAgentId = row.id;//用户id
            if (agentId == null){
                this.$alert('agent id Error');//代理id异常
                return;
            }
            switch (type){
                case 1:
                    // 编辑代理商信息
                    this.$refs.agentListInfoRef.setShow(true,agentId);
                    break;
                case 2:
                    //充值余额
                    this.$refs.agentLIstChangeMoneyRef.openDialog(agentId,row.username,1);
                    break;
                case 4:
                    //回收余额
                    this.$refs.agentLIstChangeMoneyRef.openDialog(agentId,row.username,2);
                    break;
                case 5:
                    //查询卡密
                    this.$router.push({name:'key.keyList',params:{agent:row.username,type:1,status:3}});
                    break;
                case 6:
                    //下级用户管理
                    this.$router.push({name:'agent.subordinateUsers',params:{agent:row.username}});
                    break;
                case 7:
                    //日志查询
                    this.$router.push({name:'log.agentLog',params:{agent:row.username}});
                    break;
            }
        },
        //接收子组件传来的筛选后的代理商数据
        watchFilterData(agentArray){
            console.log('watchFilterData',agentArray);
            //向顶级父组件传入筛选后的用户数据
            this.$refs.agentListFilterRef.setShow(false);
            this.filterData = agentArray;
            this.viewTableData(2);
        },
        //打开筛选器
        openFilter(){
            this.$refs.agentListFilterRef.setShow(true);
        },
        /**
         * 切换表格显示的数据[全部数据/筛选数据]
         * @param mode 1=全部数据 2=筛选数据
         */
        viewTableData(mode){
            this.loading = true;
            this.$notify({message:this.lang['viewTableData.wait'],type: 'info',});
            if (mode === 1){
                // this.changePage(1)
                paging.init(this.tableDataAll,20);
                this.changePage(1)
            }else {
                // this.tableData = this.filterData;
                // this.pageShow = false;
                paging.init(this.filterData,20);
                this.changePage(1)
                this.$message({
                    type:'success',
                    message:this.lang['viewTableData.tips'] + this.filterData.length,
                });
            }
            this.loading = false;
        },
        //[初始化]检查当前代理商是否有越级管理功能
        initAgentDataType(){
            //定义允许使用该功能的白名单
            let arr = [
                //新思睿服务器
                'yue','xiao',
                //魔将1名单
                'testagent','mjkj','mjkjy',
                //魔将2名单
                'TestAgent','mjkj2',
                //魔将3名单
                'RootAgent','mjkj3',
                //千机变
                'testqjb','qjb2022',
            ];
            //检查当前代理商是否有权限使用这功能
            this.agentDataTypeVisible = arr.includes(this.agentUserName);
        },
        //触发切换下级代理数据类型
        changeAgentDataType(){
            this.$message({message:this.lang['changeAgentDataType.wait'],info:'type'});
            this.getAgentList();
        },
        //导出代理数据
        outputData(){
            this.$confirm(this.lang['outputData.message'],'Warning',{
                confirmButtonText:this.lang['outputData.confirmButtonText'],
                cancelButtonText:this.lang['outputData.cancelButtonText'],
            }).then(()=>this.makeOutPutUserData(paging.getAllData()))
                .catch(()=>this.makeOutPutUserData(this.selectTableData));
        },
        //导出代理数据 功能函数
        makeOutPutUserData(arr){
            if (arr.length <= 0){
                this.$alert(this.lang['makeOutPutUserData.error.length']);
                return;
            }
            this.$message({message:this.lang['makeOutPutUserData.tips.length'] + arr.length,type:'info'});
            let line = '\r\n';
            let str = '';
            for (let arrKey in arr) {
                let temp = arr[arrKey];
                let res = [
                    temp['username'],
                    temp['name'],
                    temp['contact'],
                    temp['nickname'],
                    temp['money_reg'],
                    temp['money_rec'],
                    temp['leader'],
                    temp['reg_time'],
                    temp['login_time'],
                    temp['status'] == 1 ? '√' : '×',
                ];
                str += res.toString() + line;
            }
            let filed = ['USERNAME','NAME','CONTACT','NICKNAME','MONEY RECHARGE','MONEY REGISTER','LEADER','REGISTRATION TIME','LOGIN TIME','STATUS',];
            let data = filed.toString() + line + str;
            this.$helper.downLoad(data,'AgentData.csv');
        },
        // //[子组件]收到通知更新表格数据
        // updateTable(){
        //     //延迟一秒后强制刷新页面
        //     // setTimeout(()=>{
        //     //     this.$router.go(0);
        //     // },1000);
        //     this.$confirm(
        //         this.lang['updateTable.message'],
        //         this.lang['updateTable.title'],
        //         {
        //             cancelButtonText:'×',
        //             confirmButtonText: '√',
        //         },
        //     ).then(()=>{
        //         //延迟x毫秒后刷新页面
        //         setTimeout(()=>{
        //             this.$router.go(0);
        //         },10);
        //     }).catch(()=>this.$notify({message:this.lang['updateTable.catch'],type:'warning',duration:8000}))
        // },
        /**
         * [子组件]收到通知更新表格数据
         * 无缝更新表格的某个代理商数据,并显示出来
         * 代理商感知不到数据已更新
         */
        updateTable(){
            this.isLoading = true;
            this.$api.post('Agent.AgentData/getAgentData',{agentId:this.nowEditAgentId})
                .then(res=>{
                    if (!this.$helper.checkRes(res)){
                        this.confirmRefreshPage();
                    }
                    this.updateAgentListData(res.data.data);
                })
                .finally(()=>this.loading = false);
        },
        /**
         * [updateTable]更新[this]与[分页类]的数据,并更新当前表格显示
         * @param agentInfo
         */
        updateAgentListData(agentInfo){
            this.tableDataAll = this.updateArr(this.tableDataAll,this.nowEditAgentId,agentInfo);
            this.filterData = this.updateArr(this.filterData,this.nowEditAgentId,agentInfo);
            let paginData = this.updateArr(paging.getAllData(),this.nowEditAgentId,agentInfo);
            let pageConfig = {
                size:paging.pageSzie,
                page:paging.pageNum,
            };
            paging.init(paginData,pageConfig.size);
            paging.setPage(pageConfig.page);
            this.tableData = paging.getPageData();
        },
        /**
         * 基于指定代理商数据,更新代理商数据数组的成员
         * @param arr 代理商数据数组
         * @param id 代理商id
         * @param info 代理商数据信息
         * @return {*}
         */
        updateArr(arr,id,info){
            for (let arrKey in arr) {
                let temp = arr[arrKey];
                if (temp.id === id){
                    setTimeout(
                        ()=>this.$message({message:this.lang['updateArr.tips'],type:'success'}),
                        10
                    );
                    arr[arrKey] = info;
                    break;
                }
            }
            return arr;
        },
        /**
         * [updateTable]无缝更新失败,询问代理商是否要刷新页面以更新数据
         */
        confirmRefreshPage(){
            this.$confirm(
                this.lang['updateTable.message'],
                this.lang['updateTable.title'],
                {
                    cancelButtonText:'×',
                    confirmButtonText: '√',
                },
            ).then(()=>{
                //延迟x毫秒后刷新页面
                setTimeout(()=>{
                    this.$router.go(0);
                },10);
            }).catch(()=>this.$notify({message:this.lang['updateTable.catch'],type:'warning',duration:8000}));
        },
    },
    mounted() {
        this.getAgentList();
        //检查当前代理商是否有越级管理功能
        this.initAgentDataType();
    }
}
</script>

<style scoped>
.tools{
    margin-bottom: 5px;
}
</style>